const allInputs = document.querySelectorAll('.c-input-number');

allInputs?.forEach(inputNumber => {
  const inputValue = inputNumber?.querySelector('.c-input-number__value') as HTMLDivElement;
  const input = inputNumber?.querySelector('.c-input-number__input') as HTMLInputElement;
  const btnAdd = inputNumber?.querySelector('.c-input-number__add') as HTMLButtonElement;
  const btnRemove = inputNumber?.querySelector('.c-input-number__remove') as HTMLButtonElement;

  input.addEventListener('change', () => {
    inputValue.textContent = input.value
  });

  btnAdd.addEventListener('click', () => {
    input.stepUp();
    // inputValue.textContent = input.value;
    input.dispatchEvent(new Event('change', { bubbles: true }));
  });

  btnRemove.addEventListener('click', () => {
    if (parseInt(input.value) > 0) {
      input.stepDown();
      // inputValue.textContent = input.value;
      input.dispatchEvent(new Event('change', { bubbles: true }));
    }
  });
});